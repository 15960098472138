const applicationActionMixin = {
    data() {
        return {
            countries: [],
            cities: [],
            airports: [],
            services: [],
            serviceOptions: [],
            serviceParameters: {},
            customsDocuments: [],
            animalTypes: [],
        }
    },
    methods: {
        fetchServiceParameters(serviceCategoryId) {
            return this.axios.get(this.$urls.application.serviceParameters.url, { params: { service_category_id: serviceCategoryId } })
                .then(response => {
                    this.serviceParameters = response.data.data;
                }).catch(() => {
                });
        },
        fetchCustomsDocuments() {
            this.axios.get(this.$urls.application.customsDocuments.url)
                .then(response => {
                    this.customsDocuments = response.data.data;
                }).catch(() => {
            });
        },
        fetchServices() {
            this.axios.get(this.$urls.application.services.url)
                .then(response => {
                    this.services = response.data.data;
                }).catch(() => {
            });
        },
        fetchServiceOptions() {
            this.axios.get(this.$urls.application.serviceOptions.url)
                .then(response => {
                    this.serviceOptions = response.data.data;
                }).catch(() => {
            });
        },
        fetchCountries(params = { count: 50 }) {
            this.axios.get(this.$urls.application.countries.url, { params: this.cleanObject(params) })
                .then(response => {
                    this.countries = response.data.data.items;
                }).catch(() => {
            });
        },

    }
};

export default applicationActionMixin;
const parcelMixin = {
    data() {
        return {
            parcels: [],
            parcelForms: {
                specialty: {
                    parcel_type_id: '',
                    container_type_id: '',
                    width: '',
                    height: '',
                    length: '',
                    weight: '',
                    quantity: 1,
                    wheel_base: '',
                    weight_unit_id: this.firstWeightUnitId,
                    dimension_unit_id: this.firstDimensionUnitId,
                },
                perishable: {
                    parcel_type_id: '',
                    parcel_type: {
                        children: []
                    },
                    width: '',
                    height: '',
                    length: '',
                    weight: '',
                    quantity: 1,
                    weight_unit_id: this.firstWeightUnitId,
                    dimension_unit_id: this.firstDimensionUnitId,
                },
                pharma: {
                    parcel_type_id: '',
                    width: '',
                    height: '',
                    length: '',
                    weight: '',
                    quantity: 1,
                    weight_unit_id: this.firstWeightUnitId,
                    dimension_unit_id: this.firstDimensionUnitId,
                },
                medical: {
                    parcel_type_id: '',
                    width: '',
                    height: '',
                    length: '',
                    weight: '',
                    quantity: 1,
                    weight_unit_id: this.firstWeightUnitId,
                    dimension_unit_id: this.firstDimensionUnitId,
                },
                generalCargo: {
                    title: '',
                    width: '',
                    height: '',
                    length: '',
                    weight: '',
                    quantity: 1,
                    weight_unit_id: this.firstWeightUnitId,
                    dimension_unit_id: this.firstDimensionUnitId,
                },
                petsLive: {
                    title: '',
                    age: '',
                    quantity: 1,
                    weight: 0,
                    animal_type_id: '',
                    animal_kind_id: '',
                    animal_kind: {
                        children: []
                    },
                    age_unit_id: '',
                    is_household: false,
                    width: '',
                    height: '',
                    length: '',
                    weight_unit_id: this.firstWeightUnitId,
                    dimension_unit_id: this.firstDimensionUnitId,
                }
            }
        }
    },
    computed: {
        firstWeightUnitId() {

            if ( this.parcels.length >= 1 ) {
                let parcel = this.parcels[0];
                return parcel.weight_unit_id;
            }

            //KG
            return 4;
        },
        firstDimensionUnitId() {
            if ( this.parcels.length >= 1 ) {
                let parcel = this.parcels[0];
                return parcel.dimension_unit_id;
            }

            //CM
            return 1;
        },
    },
    methods: {

        addParcel(serviceCategoryId) {
            switch ( serviceCategoryId ) {

                case this.GENERAL_CARGO_ID:
                case this.PARCELS_ID:
                case this.HOME_MOVE_ID:
                    this.parcels.push(this.setUnits({ ...this.parcelForms.generalCargo }));
                    break;

                case this.PETS_LIVE_ID:
                    this.parcels.push(this.setUnits({ ...this.parcelForms.petsLive }));
                    break;

                case this.PERISHABLES_ID:
                    this.parcels.push(this.setUnits({ ...this.parcelForms.perishable }));
                    break;

                case this.PHARMA_ID:
                    this.parcels.push(this.setUnits({ ...this.parcelForms.pharma }));
                    break;
                case this.MEDICAL_ID:
                    this.parcels.push(this.setUnits({ ...this.parcelForms.medical }));
                    break;

                case this.SPECIALTY_ID:

                    let parcel = this.setUnits({ ...this.parcelForms.specialty });

                    if ( this.specialtyType === 'specialty_vehicles' || this.specialtyType === 'specialty_fashion' )
                        parcel.parcel_type_id = this.specialtyType;


                    this.parcels.push(parcel);
                    break;
            }
        },
        setUnits(parcel) {
            parcel.dimension_unit_id = this.firstDimensionUnitId;
            parcel.weight_unit_id = this.firstWeightUnitId;

            return parcel;

        },
        removeParcel(index) {

            if ( this.parcels.length <= index || index < 0 )
                return;

            if ( this.parcels.length < 2 )
                return;

            Swal.fire({
                title: this.getTranslation('areYouSure'),
                text: this.getTranslation('youCantRevertThis'),
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: this.getTranslation('yes'),
                cancelButtonText: this.getTranslation('cancel')
            }).then(result => {

                if ( result.isConfirmed ) {

                    this.parcels.splice(index, 1);

                }

            })
        },
    }
};
export default parcelMixin;
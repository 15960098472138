<template>
  <!--Request a Quote -->
  <section class="whychoose-1 secpadd">
    <div class="container">
      <div class="row quote1top">
        <div class="col-md-8">
          <div class="fh-section-title clearfix  text-left version-dark paddbtm40">
            <h2>{{ getTranslation(item.slug) }}</h2>
          </div>
          <p>{{ getTranslation(item.slug + '_desc') }}</p>
        </div>
        <div class="col-md-4">
          <img src="@/assets/portal/images/resources/cargocar.png" alt="">
        </div>
      </div>
      <div class="row quote1forms">
        <div class="col-md-12 section">
          <form>
            <div class="fh-form request-form">

              <!--  app form row-->
              <div class="row">
                <!--  -->
                <div class="col-md-6">
                  <div class="row">
                    <div class="field col-md-12">
                      <label>{{ getTranslation('title') }}<span class="require">*</span></label>
                      <input class="form-control" :placeholder="getTranslation('title')"
                             :class="{'is-invalid':$v.form.title.$error}" type="text" v-model.trim="form.title">
                      <div v-if="$v.form.title.$error" class="invalid-feedback">
                        {{ getTranslation('requiredField') }}
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="field col-md-12">
                      <label>{{ getTranslation('promocode') }}</label>
                      <input class="form-control" :placeholder="getTranslation('promocode')" type="text"
                             v-model.trim="form.coupon_code">
                    </div>
                  </div>
                  <div class="row">
                    <div class="field col-md-12">
                      <label>{{ getTranslation('serviceOptions') }} <span class="require">*</span></label>
                      <div v-for="service in serviceOptions">
                        <div class="form-check mt-2">
                          <input class="form-check-input" type="radio" v-model="form.service_option_id"
                                 :value="service.id"
                                 :id="service.id">
                          <label class="form-check-label ml-2" :for="service.id"
                                 :class="{'is-invalid':$v.form.service_option_id.$error}">
                            {{ getTranslation(form.by_land ? service.title.land : service.title.air) }}
                          </label>
                        </div>
                      </div>
                      <div v-if="$v.form.service_option_id.$error" class="invalid-feedback">
                        {{ getTranslation('chooseOneField') }}
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="field col-md-6 col-sm-12">
                      <label>{{ getTranslation('shipmentDate') }}<span
                          class="require">*</span></label>
                      <DatePicker
                          v-model="form.shipment_date"
                          valueType="format"
                      />
                      <div v-if="$v.form.shipment_date.$error" class="invalid-feedback">
                        {{ getTranslation('requiredField') }}
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="field col-md-6" style="margin-top: 10px !important">
                      <CountrySelect ref="originCountrySelect" v-model="form.origin_country_id" required
                                     :form-label="getTranslation('originCountry')"/>
                    </div>
                    <div v-if="showOriginCities" class="field col-md-6" style="margin-top: 10px !important">
                      <CitySelect v-if="showOriginCities" :add-class="'col-md-12'" :form-label="'originCity'"
                                  :country-id="form.origin_country_id" v-model="form.origin_city_id"/>
                    </div>
                  </div>
                  <div class="row">
                    <div class="field col-md-12">
                      <label>{{ getTranslation('originAddress') }}<span class="require">*</span></label>
                      <textarea class="form-control app-textarea" :class="{'is-invalid':$v.form.address_origin.$error}"
                                rows="1" cols="3" v-model="form.address_origin"></textarea>
                      <div v-if="$v.form.address_origin.$error" class="invalid-feedback">
                        {{ getTranslation('requiredField') }}
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="field col-md-6">
                      <CountrySelect :form-label="'destinationCountry'" required v-model="form.destination_country_id"/>
                    </div>
                    <div class="field col-md-6">
                      <CitySelect v-if="showCities" :form-label="'destinationCity'"
                                  :country-id="form.destination_country_id" v-model="form.destination_city_id"/>
                    </div>
                    <div class="field col-md-6">
                      <AirportSelect v-if="showAirports && !form.by_land" :form-label="'destinationAirport'"
                                     v-model="form.destination_airport_id"/>
                    </div>
                  </div>
                  <div class="row">

                    <div class="col-md-12">
                      <label>{{ getTranslation('destinationAddress') }}<span
                          class="require">*</span></label>
                      <textarea class="form-control field app-textarea"
                                :class="{'is-invalid':$v.form.address_destination.$error}" rows="2"
                                v-model="form.address_destination"></textarea>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">

                  <div class="row">
                    <div class="col-md-6 col-sm-12">
                      <div class="field">
                        <label>{{ getTranslation('contactName') }} <span
                            class="require">*</span></label>
                        <input type="text" class="form-control" v-model="form.contact.name"
                               :class="{'is-invalid':$v.form.contact.name.$error}">
                        <div v-if="$v.form.contact.name.$error" class="invalid-feedback">
                          {{ getTranslation('requiredField') }}
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6 col-sm-12">
                      <div class="field">
                        <label>{{ getTranslation('email') }} <span class="require">*</span></label>
                        <input type="email" class="form-control" :class="{'is-invalid':$v.form.contact.email.$error}"
                               v-model="form.contact.email">
                        <div v-if="$v.form.contact.email.$error" class="invalid-feedback">
                          {{ getTranslation('requiredField') }}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-12 col-sm-12">
                      <label>{{ getTranslation('mobile') }} <span
                          class="require">*</span></label>
                      <div class="field">

                        <div v-if="showPhonCodes" class="input-group">

                          <PhoneCode required :edit-item-id="form.contact.mobile_code_id"
                                     v-model="form.contact.mobile_code_id" :items="phoneCodes"/>

                          <input type="text" class="form-control" v-model="form.contact.mobile"
                                 :class="{'is-invalid':$v.form.contact.mobile.$error}">
                        </div>
                        <div v-if="$v.form.contact.mobile.$error" class="invalid-feedback">
                          {{ getTranslation('requiredField') }}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-12 col-sm-12">
                      <div class="field">
                        <label>{{ getTranslation('whatsapp') }}</label>
                        <div v-if="showPhonCodes" class="input-group">

                          <PhoneCode required :edit-item-id="form.contact.whatsapp_code_id"
                                     v-model="form.contact.whatsapp_code_id" :items="phoneCodes"/>

                          <input type="text" class="form-control" v-model="form.contact.whatsapp">
                        </div>

                      </div>
                    </div>
                  </div>

                  <div v-if="form.service_category_id === GENERAL_CARGO_ID" class="row">
                    <div class="field col-md-6 col-sm-12">
                      <label>
                        {{ getTranslation('expressCargo') }}
                      </label>
                      <div class="form-check">
                        <input class="form-check-input" type="checkbox" v-model="form.express" id="defaultCheck12">
                        <label class="form-check-label ml-2" for="defaultCheck12">
                          {{ getTranslation('isExpressCargo') }}?
                        </label>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="field col-md-6 col-sm-12">
                      <label>
                        {{ getTranslation('transportation') }}
                      </label>
                      <div class="form-check">
                        <input class="form-check-input" type="checkbox" v-model="form.by_land" id="defaultCheck1">
                        <label class="form-check-label ml-2" for="defaultCheck1">
                          {{ getTranslation('isByLand') }}?
                        </label>
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="field col-md-12 col-sm-12">
                      <label class="form-check-label mt-5" for="borderService">
                        {{ getTranslation('borderServices') }}
                      </label>

                      <div class="form-check">
                        <input class="form-check-input" type="checkbox" v-model="form.needs_border_service"
                               id="borderService">
                        <label class="form-check-label ml-2" for="borderService">
                          {{ getTranslation('needBorderService') }}?
                        </label>
                        <p v-if="form.needs_border_service" class="text-muted">
                          {{ getTranslation('customsServiceDocsDescription') }}
                        </p>
                      </div>
                      <div v-if="form.needs_border_service" style="width: 30rem;">
                        <ul class="list-group list-group-flush">
                          <li v-for="(document,key) in customsDocuments" class="list-group-item">
                            <span v-if="key !== 3">{{ key + 1 }}) </span>{{ getTranslation(document.slug) }}
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-12">
                      <label for="shortArea">{{ getTranslation('description') }}</label>
                      <textarea v-model.trim="form.description" class="field form-control app-textarea" id="shortArea"
                                rows="2"/>
                    </div>
                  </div>
                </div>
              </div>
              <!-- end app form row -->

              <!--  parcels form row-->
              <div class="row">
                <div class="col-md-6 col-sm-12">
                  <!-- specialty -->
                  <div v-if="form.service_category_id === SPECIALTY_ID" class="field col-md-12">
                    <div v-if="showServiceParameters" class="form-group">
                      <label class="center-block margbtm30">{{
                          getTranslation("Please select what you'd like to ship:")
                        }}
                        <span class="require">*</span>
                      </label>
                      <div v-for="(type,key) in serviceParameters.types" v-bind:key="key" class="col-md-6 col-sm-12">
                        <div class="form-group">
                          <div class="form-check">
                            <input class="form-check-input" type="radio" name="specialtyType" :id="type.slug"
                                   :value="type.slug" v-model="specialtyType">
                            <label class="form-check-label" :for="type.slug">
                              {{ getTranslation(type.title) }}
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- move on -->
                  <div v-if="form.service_category_id === HOME_MOVE_ID" class="field col-md-12">
                    <div class="form-group">
                      <label>{{ getTranslation("callBack") }}</label>
                      <div class="form-check">
                        <input class="form-check-input" type="checkbox" id="callRequest"
                               v-model="form.is_call_requested">
                        <label class="form-check-label ml-2" for="callRequest">
                          {{ getTranslation("leaveRequest") }}?
                        </label>
                      </div>
                    </div>
                  </div>

                  <!--     parcels add            -->
                  <div class="field col-md-12"
                       v-if="!form.is_call_requested && form.service_category_id !== DANGEROUS_GOODS_ID">
                    <label class="mt-4">{{ getTranslation('parcels') }} <span class="require">*</span></label>
                    <a href="javascript:void(0)" :title="getTranslation('add')"
                       class="btn  btn--system btn--white btn--square btn-sm"
                       @click="addParcel(item.id)">
                      + {{ getTranslation('addParcel') }}
                    </a>
                  </div>
                  <!--    end parcels add            -->

                  <!--     parcels          -->
                  <div v-if="specialtyType === 'specialty_human_remains'">
                    <div v-for="(parcel,key) in parcels" v-bind:key="key" class="card mt-3">
                      <div class="card-header">
                        <span class="card-title font-2xl"> № {{ key + 1 }}</span>
                        <div class="card-header-actions">
                          <button @click="removeParcel(key)" type="button" class="btn btn-danger"
                                  :disabled="parcels.length < 2">
                            <CIcon name="cil-delete"/>
                          </button>
                        </div>
                      </div>
                      <div class="card-body">
                        <div class="form-row" v-if="showServiceParameters">
                          <div class="col-md-6 col-sm-12">
                            <div class="form-group">
                              <label>{{ getTranslation('typeOfRemains') }}
                                <span class="require">*</span></label>
                              <VSelect
                                  :options="serviceParameters.options"
                                  label="title"
                                  v-model="parcel.parcel_type_id"
                                  :reduce="option=>option.id"
                                  :placeholder="getTranslation('selectFromList')"
                              />
                            </div>
                          </div>
                          <div class="col-md-6 col-sm-12">
                            <div class="form-group">
                              <label>{{ getTranslation('airContainerType') }}
                                <span class="require">*</span></label>
                              <VSelect
                                  :options="serviceParameters.airContainerTypes"
                                  label="title"
                                  :reduce="option=>option.id"
                                  v-model="parcel.container_type_id"
                                  :placeholder="getTranslation('selectFromList')"
                              />
                            </div>
                          </div>
                        </div>
                        <div class="form-group">
                          <label>{{ getTranslation('quantity') }}</label>
                          <input type="number" min="0" class="form-control" v-model.number="parcel.quantity"/>
                        </div>

                        <div class="form-row row mt-3">
                          <div class="col-md-6 col-sm-12">
                            <label class="form__label">{{ getTranslation('units') }}</label>
                            <v-select
                                :options="unitOptions.dimension"
                                v-model="parcel.dimension_unit_id"
                                :reduce="item=>item.id"
                                label="title"
                            />
                          </div>
                        </div>
                        <div class="form-row row">
                          <div class="col-md-4 col-sm-12">
                            <label>{{ getTranslation('length') }}</label>
                            <input type="number" min="0" step="any" class="form-control" v-model="parcel.length"/>
                          </div>
                          <div class="col-md-4 col-sm-12">
                            <label class="form__label">{{ getTranslation('width') }}</label>
                            <input type="number" min="0" step="any" class="form-control" v-model="parcel.width"/>
                          </div>
                          <div class="col-md-4 col-sm-12">
                            <label class="form__label">{{ getTranslation('height') }}</label>
                            <input type="number" min="0" step="any" class="form-control" v-model="parcel.height"/>
                          </div>
                        </div>
                        <div class="form-row row margtop30">
                          <div class="col-md-6 col-sm-12">
                            <label>{{ getTranslation('weight') }}</label>
                            <input type="number" min="0" step="any" class="form-control"
                                   v-model.number="parcel.weight"/>
                          </div>
                          <div class="col-md-6 col-sm-12">
                            <label>{{ getTranslation('units') }}</label>
                            <v-select
                                :options="unitOptions.weight"
                                v-model="parcel.weight_unit_id"
                                :reduce="item=>item.id"
                                label="title"
                                :disabled="key > 0"
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>

                  <div v-if="specialtyType === 'specialty_valuables'">
                    <div v-for="(parcel,key) in parcels" v-bind:key="key" class="mt-3 card">
                      <div class="card-header">
                        <span class="card-title font-2xl"> № {{ key + 1 }}</span>
                        <div class="card-header-actions">
                          <button @click="removeParcel(key)" type="button" class="btn btn-danger"
                                  :disabled="parcels.length < 2">
                            <CIcon name="cil-delete"/>
                          </button>
                        </div>
                      </div>
                      <div class="card-body">
                        <div class="form-row row" v-if="showServiceParameters">
                          <div class="col-md-12 col-sm-12">
                            <div class="form-group">
                              <label>{{ getTranslation('typeOfRemains') }}
                                <span class="require">*</span></label>
                              <VSelect
                                  :options="serviceParameters.valuableTypes"
                                  label="title"
                                  v-model="parcel.parcel_type_id"
                                  :reduce="option=>option.id"
                                  :placeholder="getTranslation('selectFromList')"
                              />
                            </div>
                          </div>
                        </div>
                        <div class="form-group">
                          <label>{{ getTranslation('quantity') }}</label>
                          <input type="number" min="0" class="form-control" v-model.number="parcel.quantity"/>
                        </div>

                        <div class="form-row row">
                          <div class="col-md-6 col-sm-12">
                            <label>{{ getTranslation('units') }}</label>
                            <v-select
                                :options="unitOptions.dimension"
                                v-model="parcel.dimension_unit_id"
                                :reduce="item=>item.id"
                                label="title"
                                :disabled="key > 0"
                            />
                          </div>
                        </div>
                        <div class="form-row row">
                          <div class="col-md-4 col-sm-12">
                            <label>{{ getTranslation('length') }}</label>
                            <input type="number" min="0" step="any" class="form-control"
                                   v-model.number="parcel.length"/>
                          </div>
                          <div class="col-md-4 col-sm-12">
                            <label>{{ getTranslation('width') }}</label>
                            <input type="number" min="0" step="any" class="form-control" v-model.number="parcel.width"/>
                          </div>
                          <div class="col-md-4 col-sm-12">
                            <label>{{ getTranslation('height') }}</label>
                            <input type="number" min="0" step="any" class="form-control"
                                   v-model.number="parcel.height"/>
                          </div>
                        </div>
                        <div class="form-row row mt-3">
                          <div class="col-md-6 col-sm-12">
                            <label>{{ getTranslation('weight') }}</label>
                            <input type="number" min="0" step="any" class="form-control"
                                   v-model.number="parcel.weight"/>
                          </div>
                          <div class="col-md-6 col-sm-12">
                            <label>{{ getTranslation('units') }}</label>
                            <v-select
                                :options="unitOptions.weight"
                                v-model="parcel.weight_unit_id"
                                :reduce="item=>item.id"
                                label="title"
                                :disabled="key > 0"
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>

                  <div v-if="specialtyType === 'specialty_vehicles' || specialtyType==='specialty_fashion'">
                    <div v-for="(parcel,key) in parcels" v-bind:key="key" class="mt-3 card">
                      <div class="card-header">
                        <span class="card-title font-2xl"> № {{ key + 1 }}</span>
                        <div class="card-header-actions">
                          <button @click="removeParcel(key)" type="button" class="btn btn-danger"
                                  :disabled="parcels.length < 2">
                            <CIcon name="cil-delete"/>
                          </button>
                        </div>
                      </div>
                      <CCardBody>
                        <div class="form-group">
                          <label>{{ getTranslation('quantity') }}</label>
                          <input type="number" min="0" class="form-control" v-model="parcel.quantity"/>
                        </div>

                        <div class="form-row row">
                          <div class="col-md-6 col-sm-12">
                            <label>{{ getTranslation('units') }}</label>
                            <v-select
                                :options="unitOptions.dimension"
                                v-model="parcel.dimension_unit_id"
                                :reduce="item=>item.id"
                                label="title"
                                :disabled="key > 0"
                            />
                          </div>
                        </div>
                        <div class="form-row row mt-3">
                          <div class="col-md-4 col-sm-12">
                            <label class="form__label">{{ getTranslation('length') }}</label>
                            <input type="number" min="0" step="any" class="form-control" v-model="parcel.length"/>
                          </div>
                          <div class="col-md-4 col-sm-12">
                            <label class="form__label">{{ getTranslation('width') }}</label>
                            <input type="number" min="0" step="any" class="form-control" v-model="parcel.width"/>
                          </div>
                          <div class="col-md-4 col-sm-12">
                            <label class="form__label">{{ getTranslation('height') }}</label>
                            <input type="number" min="0" step="any" class="form-control" v-model="parcel.height"/>
                          </div>
                        </div>
                        <div v-if="specialtyType===50" class="form-row row mt-3">
                          <div class="col-md-6 col-sm-12">
                            <div class="form-group">
                              <label>{{ getTranslation('vehicleWheelBase') }}</label>
                              <input type="number" min="0" class="form-control" v-model.number="parcel.wheel_base"/>
                            </div>
                          </div>
                          <div class="col-md-6 col-sm-12">
                            <label>{{ getTranslation('units') }}</label>
                            <v-select
                                :options="unitOptions.dimension"
                                v-model="parcel.dimension_unit_id"
                                :reduce="item=>item.id"
                                label="title"
                                :disabled="key > 0"
                            />
                          </div>
                        </div>
                        <div class="form-row row mt-3">
                          <div class="col-md-6 col-sm-12">
                            <label>{{ getTranslation('weight') }}</label>
                            <input type="number" min="0" step="any" class="form-control"
                                   v-model.number="parcel.weight"/>
                          </div>
                          <div class="col-md-6 col-sm-12">
                            <label>{{ getTranslation('units') }}</label>
                            <v-select
                                :options="unitOptions.weight"
                                v-model="parcel.weight_unit_id"
                                :reduce="item=>item.id"
                                label="title"
                                :disabled="key > 0"
                            />
                          </div>
                        </div>
                      </CCardBody>
                    </div>

                  </div>

                  <div
                      v-if="form.service_category_id === GENERAL_CARGO_ID || form.service_category_id === PARCELS_ID || form.service_category_id===HOME_MOVE_ID">
                    <div v-for="(parcel,key) in parcels" v-bind:key="key" class="card mt-3">
                      <div class="card-header">
                        <span class="card-title font-2xl"> № {{ key + 1 }}</span>
                        <div class="card-header-actions">
                          <button @click="removeParcel(key)" type="button" class="btn btn-danger"
                                  :disabled="parcels.length < 2">
                            <CIcon name="cil-delete"/>
                          </button>
                        </div>
                      </div>
                      <div class="card-body">
                        <div class="form-group" v-if="form.service_category_id===HOME_MOVE_ID">
                          <label>{{ getTranslation('title') }}</label>
                          <input type="text" class="form-control" v-model="parcel.title"/>
                        </div>
                        <div class="form-group">
                          <label>{{ getTranslation('quantity') }}</label>
                          <input type="number" min="0" class="form-control" v-model="parcel.quantity"/>
                        </div>
                        <div class="form-row row mt-3">
                          <div class="col-md-6 col-sm-12">
                            <label>{{ getTranslation('units') }}</label>
                            <v-select
                                :options="unitOptions.dimension"
                                v-model="parcel.dimension_unit_id"
                                :reduce="item=>item.id"
                                label="title"
                                :disabled="key > 0"
                            />
                          </div>
                        </div>
                        <div class="form-row row mt-3">
                          <div class="col-md-4 col-sm-6">
                            <label>{{ getTranslation('length') }}</label>
                            <input type="number" min="0" step="any" class="form-control" v-model="parcel.length"/>
                          </div>
                          <div class="col-md-4 col-sm-6">
                            <label>{{ getTranslation('width') }}</label>
                            <input type="number" min="0" step="any" class="form-control" v-model="parcel.width"/>
                          </div>
                          <div class="col-md-4 col-sm-6">
                            <label>{{ getTranslation('height') }}</label>
                            <input type="number" min="0" step="any" class="form-control" v-model="parcel.height"/>
                          </div>
                        </div>
                        <div class="form-row row mt-3">
                          <div class="field col-md-6 col-sm-12">
                            <label>{{ getTranslation('weight') }}</label>
                            <input type="number" min="0" step="any" class="form-control" v-model="parcel.weight"/>
                          </div>
                          <div class=" field col-md-6 col-sm-12">
                            <label>{{ getTranslation('units') }}</label>
                            <v-select
                                :options="unitOptions.weight"
                                v-model="parcel.weight_unit_id"
                                :reduce="item=>item.id"
                                label="title"
                                :disabled="key > 0"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- pets -->
                  <div v-if="form.service_category_id === PETS_LIVE_ID">
                    <CCard v-for="(parcel,key) in parcels" v-bind:key="key" class="mt-3">
                      <CCardHeader>
                        <span class="card-title font-2xl"> № {{ key + 1 }}</span>
                        <div class="card-header-actions">
                          <button @click="removeParcel(key)" type="button" class="btn btn-danger"
                                  :disabled="parcels.length < 2">
                            <CIcon name="cil-delete"/>
                          </button>
                        </div>
                      </CCardHeader>
                      <CCardBody>
                        <div class="form-group">
                          <label>{{ getTranslation('animalKindToBook') }} <span
                              class="require">*</span></label>
                          <VSelect
                              :options="serviceParameters.options"
                              label="title"
                              v-model="parcel.animal_kind"
                              :placeholder="getTranslation('selectFromList')"
                          />
                        </div>
                        <div v-if="parcel.animal_kind.children.length > 0" class="form-group">
                          <label>{{ getTranslation('animalTypeToBook') }} <span
                              class="require">*</span></label>
                          <VSelect
                              :options="parcel.animal_kind.children"
                              label="title"
                              :reduce="option=>option.id"
                              v-model="parcel.animal_type_id"
                              :placeholder="getTranslation('selectFromList')"
                          />
                        </div>
                        <div class="form-group">
                          <label>{{ getTranslation('breed') }}</label>
                          <input type="text" class="form-control" v-model="parcel.title"/>
                        </div>
                        <div class="form-group">
                          <label>{{ getTranslation('quantity') }}</label>
                          <input type="number" min="0" class="form-control" v-model.number="parcel.quantity"/>
                        </div>
                        <div class="form-group">
                          <div class="form-check mt-3">
                            <input class="form-check-input" type="checkbox" v-model="parcel.is_household"
                                   id="household">
                            <label class="form-check-label ml-2" for="household">
                              {{ getTranslation('isHousehold') }}?
                            </label>
                          </div>
                        </div>
                        <div class="form-row row mt-3">
                          <div class="col-md-6 col-sm-12">
                            <label>{{ getTranslation('units') }}</label>
                            <v-select
                                :options="unitOptions.dimension"
                                v-model="parcel.dimension_unit_id"
                                :reduce="item=>item.id"
                                label="title"
                                :disabled="key > 0"
                            />
                          </div>
                        </div>
                        <div class="form-row row mt-3">
                          <div class="col-md-4 col-sm-6">
                            <label>{{ getTranslation('length') }}</label>
                            <input type="number" min="0" step="any" class="form-control"
                                   v-model.number="parcel.length"/>
                          </div>
                          <div class="col-md-4 col-sm-6">
                            <label>{{ getTranslation('width') }}</label>
                            <input type="number" min="0" step="any" class="form-control" v-model.number="parcel.width"/>
                          </div>
                          <div class="col-md-4 col-sm-6">
                            <label>{{ getTranslation('height') }}</label>
                            <input type="number" min="0" step="any" class="form-control"
                                   v-model.number="parcel.height"/>
                          </div>
                        </div>
                        <div class="form-row row mt-3">
                          <div class="col-md-6 col-sm-12">
                            <label>{{ getTranslation('age') }}</label>
                            <input type="number" min="0" step="any" class="form-control" v-model.number="parcel.age"/>
                          </div>
                          <div class="col-md-6 col-sm-12">
                            <label>{{ getTranslation('units') }}</label>
                            <v-select
                                :options="unitOptions.age"
                                v-model="parcel.age_unit_id"
                                :reduce="item=>item.id"
                                label="title"
                                :disabled="key > 0"
                            />
                          </div>
                        </div>
                        <div class="row form-row mt-3">
                          <div class="col-md-6 col-sm-12">
                            <label>{{ getTranslation('weight') }}</label>
                            <input type="number" min="0" step="any" class="form-control"
                                   v-model.number="parcel.weight"/>
                          </div>
                          <div class="col-md-6 col-sm-12">
                            <label>{{ getTranslation('units') }}</label>
                            <v-select
                                :options="unitOptions.weight"
                                v-model="parcel.weight_unit_id"
                                :reduce="item=>item.id"
                                label="title"
                                :disabled="key > 0"
                            />
                          </div>
                        </div>
                      </CCardBody>
                    </CCard>
                  </div>

                  <!-- perishables -->
                  <div v-if="form.service_category_id === PERISHABLES_ID">
                    <CCard v-for="(parcel,key) in parcels" v-bind:key="key" class="mt-3">
                      <CCardHeader>
                        <span class="card-title font-2xl"> № {{ key + 1 }}</span>
                        <div class="card-header-actions">
                          <button @click="removeParcel(key)" type="button" class="btn btn-danger"
                                  :disabled="parcels.length < 2">
                            <CIcon name="cil-delete"/>
                          </button>
                        </div>
                      </CCardHeader>
                      <CCardBody>
                        <div class="form-group">
                          <label>{{ getTranslation('perishableProduct') }} <span
                              class="require">*</span></label>
                          <VSelect
                              :options="serviceParameters.options"
                              label="title"
                              v-model="parcel.parcel_type"
                              :placeholder="getTranslation('selectFromList')"
                          />
                        </div>
                        <div v-if="parcel.parcel_type.children.length > 0" class="form-group">
                          <label>{{ getTranslation('commodity') }} <span
                              class="require">*</span></label>
                          <VSelect
                              :options="parcel.parcel_type.children"
                              label="title"
                              :reduce="option=>option.id"
                              v-model="parcel.parcel_type_id"
                              :placeholder="getTranslation('selectFromList')"
                          />
                        </div>

                        <div class="form-group">
                          <label>{{ getTranslation('quantity') }}</label>
                          <input type="number" min="0" class="form-control" v-model.number="parcel.quantity"/>
                        </div>

                        <div class="form-row row">
                          <div class="col-md-6 col-sm-12">
                            <label>{{ getTranslation('units') }}</label>
                            <v-select
                                :options="unitOptions.dimension"
                                v-model="parcel.dimension_unit_id"
                                :reduce="item=>item.id"
                                label="title"
                                :disabled="key > 0"
                            />
                          </div>
                        </div>
                        <div class="form-row row">
                          <div class="col-md-4 col-sm-6">
                            <label>{{ getTranslation('length') }}</label>
                            <input type="number" min="0" step="any" class="form-control"
                                   v-model.number="parcel.length"/>
                          </div>
                          <div class="col-md-4 col-sm-6">
                            <label>{{ getTranslation('width') }}</label>
                            <input type="number" min="0" step="any" class="form-control" v-model.number="parcel.width"/>
                          </div>
                          <div class="col-md-4 col-sm-6">
                            <label>{{ getTranslation('height') }}</label>
                            <input type="number" min="0" step="any" class="form-control"
                                   v-model.number="parcel.height"/>
                          </div>
                        </div>
                        <div class="form-row mt-3 row">
                          <div class="col-md-6 col-sm-12">
                            <label>{{ getTranslation('weight') }}</label>
                            <input type="number" min="0" step="any" class="form-control"
                                   v-model.number="parcel.weight"/>
                          </div>
                          <div class="col-md-6 col-sm-12">
                            <label>{{ getTranslation('units') }}</label>
                            <v-select
                                :options="unitOptions.weight"
                                v-model="parcel.weight_unit_id"
                                :reduce="item=>item.id"
                                label="title"
                                :disabled="key > 0"
                            />
                          </div>
                        </div>

                      </CCardBody>
                    </CCard>
                  </div>

                  <!-- pharma -->
                  <div v-if="form.service_category_id === PHARMA_ID">
                    <CCard v-for="(parcel,key) in parcels" v-bind:key="key" class="mt-3">
                      <CCardHeader>
                        <span class="card-title font-2xl"> № {{ key + 1 }}</span>
                        <div class="card-header-actions">
                          <button @click="removeParcel(key)" type="button" class="btn btn-danger"
                                  :disabled="parcels.length < 2">
                            <CIcon name="cil-delete"/>
                          </button>
                        </div>
                      </CCardHeader>
                      <CCardBody>
                        <div class="form-group">
                          <label>{{ getTranslation('pharmaProduct') }} <span
                              class="require">*</span></label>
                          <VSelect
                              :options="serviceParameters.options"
                              label="title"
                              v-model="parcel.parcel_type_id"
                              :reduce="option=>option.id"
                              :placeholder="getTranslation('selectFromList')"
                          />
                        </div>

                        <div class="form-group">
                          <label>{{ getTranslation('quantity') }}</label>
                          <input type="number" min="0" class="form-control" v-model.number="parcel.quantity"/>
                        </div>

                        <div class="form-row row">
                          <div class="col-md-6 col-sm-12">
                            <label>{{ getTranslation('units') }}</label>
                            <v-select
                                :options="unitOptions.dimension"
                                v-model="parcel.dimension_unit_id"
                                :reduce="item=>item.id"
                                label="title"
                                :disabled="key > 0"
                            />
                          </div>
                        </div>
                        <div class="form-row row">
                          <div class="col-md-4 col-sm-6">
                            <label>{{ getTranslation('length') }}</label>
                            <input type="number" min="0" step="any" class="form-control"
                                   v-model.number="parcel.length"/>
                          </div>
                          <div class="col-md-4 col-sm-6">
                            <label>{{ getTranslation('width') }}</label>
                            <input type="number" min="0" step="any" class="form-control" v-model.number="parcel.width"/>
                          </div>
                          <div class="col-md-4 col-sm-6">
                            <label>{{ getTranslation('height') }}</label>
                            <input type="number" min="0" step="any" class="form-control"
                                   v-model.number="parcel.height"/>
                          </div>
                        </div>
                        <div class="form-row mt-3 row">
                          <div class="col-md-6 col-sm-12">
                            <label>{{ getTranslation('weight') }}</label>
                            <input type="number" min="0" step="any" class="form-control"
                                   v-model.number="parcel.weight"/>
                          </div>
                          <div class="col-md-6 col-sm-12">
                            <label>{{ getTranslation('units') }}</label>
                            <v-select
                                :options="unitOptions.weight"
                                v-model="parcel.weight_unit_id"
                                :reduce="item=>item.id"
                                label="title"
                                :disabled="key > 0"
                            />
                          </div>
                        </div>

                      </CCardBody>
                    </CCard>
                  </div>

                  <!-- medical -->
                  <div v-if="form.service_category_id === MEDICAL_ID">
                    <CCard v-for="(parcel,key) in parcels" v-bind:key="key" class="mt-3">
                      <CCardHeader>
                        <span class="card-title font-2xl"> № {{ key + 1 }}</span>
                        <div class="card-header-actions">
                          <button @click="removeParcel(key)" type="button" class="btn btn-danger"
                                  :disabled="parcels.length < 2">
                            <CIcon name="cil-delete"/>
                          </button>
                        </div>
                      </CCardHeader>
                      <CCardBody>
                        <div class="form-group">
                          <label>{{ getTranslation('medicalProduct') }} <span
                              class="require">*</span></label>
                          <VSelect
                              :options="serviceParameters.options"
                              label="title"
                              v-model="parcel.parcel_type_id"
                              :reduce="option=>option.id"
                              :placeholder="getTranslation('selectFromList')"
                          />
                        </div>

                        <div class="form-group">
                          <label>{{ getTranslation('quantity') }}</label>
                          <input type="number" min="0" class="form-control" v-model.number="parcel.quantity"/>
                        </div>

                        <div class="form-row row">
                          <div class="col-md-6 col-sm-12">
                            <label>{{ getTranslation('units') }}</label>
                            <v-select
                                :options="unitOptions.dimension"
                                v-model="parcel.dimension_unit_id"
                                :reduce="item=>item.id"
                                label="title"
                                :disabled="key > 0"
                            />
                          </div>
                        </div>
                        <div class="form-row row">
                          <div class="col-md-4 col-sm-6">
                            <label>{{ getTranslation('length') }}</label>
                            <input type="number" min="0" step="any" class="form-control"
                                   v-model.number="parcel.length"/>
                          </div>
                          <div class="col-md-4 col-sm-6">
                            <label>{{ getTranslation('width') }}</label>
                            <input type="number" min="0" step="any" class="form-control" v-model.number="parcel.width"/>
                          </div>
                          <div class="col-md-4 col-sm-6">
                            <label>{{ getTranslation('height') }}</label>
                            <input type="number" min="0" step="any" class="form-control"
                                   v-model.number="parcel.height"/>
                          </div>
                        </div>
                        <div class="form-row mt-3 row">
                          <div class="col-md-6 col-sm-12">
                            <label>{{ getTranslation('weight') }}</label>
                            <input type="number" min="0" step="any" class="form-control"
                                   v-model.number="parcel.weight"/>
                          </div>
                          <div class="col-md-6 col-sm-12">
                            <label>{{ getTranslation('units') }}</label>
                            <v-select
                                :options="unitOptions.weight"
                                v-model="parcel.weight_unit_id"
                                :reduce="item=>item.id"
                                label="title"
                                :disabled="key > 0"
                            />
                          </div>
                        </div>

                      </CCardBody>
                    </CCard>
                  </div>
                </div>
              </div>
              <!-- end parcels form row -->

              <div class="col-md-6">
                <p @click.prevent="saveApplication" class="field submit">
                  <input :value="getTranslation('submit')" class="fh-btn" type="submit">
                </p>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </section>
  <!--Request a Quote end -->
</template>

<script>

import required from "vuelidate/lib/validators/required";
import generalMixin from "@/components/mixins/General";
import CountrySelect from "@/components/shared/CountrySelect";
import CitySelect from "@/components/shared/CitySelect";
import AirportSelect from "@/components/shared/AirportSelect";
import applicationAction from "@/views/admin/applications/mixins/applicationAction";
import PhoneCode from "@/components/shared/PhoneCode";
import phoneMixin from "@/components/mixins/PhoneCode";
import parcelMixin from "@/views/admin/applications/mixins/parcels";

export default {
  name: "SendApplication",
  components: {PhoneCode, AirportSelect, CitySelect, CountrySelect},
  mixins: [generalMixin, applicationAction, phoneMixin, parcelMixin],
  data() {
    return {
      item: {id: null},
      options: {
        options: [],
        airContainerTypes: []
      },
      serviceParameters: {},
      serviceOptions: [],
      customsDocuments: [],
      specialtyType: '',
      showAirports: false,
      showCities: false,
      showOriginCities: false,
      showServiceParameters: false,
      form: {
        title: '',
        coupon_code: '',
        service_category_id: '',
        origin_country_id: '',
        origin_city_id: '',
        destination_country_id: '',
        destination_airport_id: '',
        destination_city_id: '',
        address_origin: '',
        address_destination: '',
        shipment_date: '',
        parcel_count: 1,
        parcel_weight: 0,
        weight_unit_id: '',
        total_price: 0,
        by_land: false,
        express: false,
        needs_border_service: false,
        is_call_requested: false,
        service_option_id: '',
        description: '',
        parcels: [],
        contact: {
          name: '',
          type: 1,
          email: '',
          mobile: '',
          whatsapp: '',
          mobile_code_id: 118,
          whatsapp_code_id: 118,
        }
      },
      file: {},
      documents: [{
        title: '',
        description: '',
        type_id: '',
        files: []
      }],

      document: {
        title: '',
        description: '',
        type_id: '',
        files: []
      },
    }
  },
  created() {
    this.fetchService().then(() => {
      this.setPortalHeadings({
        title: this.getTranslation(this.item.slug),
        breadcrumbs: [
          {
            label: this.getTranslation('home'),
            route: '/'
          },
          {
            label: this.getTranslation('bookShipment'),
            route: '/'
          },
          {
            label: this.getTranslation(this.item.slug)
          }
        ]
      });

      if (this.$route.params.type !== 'home_move')
        this.addParcel(this.item.id);

      if (this.$route.params.type !== 'general_cargo')
        this.fetchServiceParameters().then(() => {
          this.showServiceParameters = true;
        });

      this.fetchUnitOptions();

      this.form.service_category_id = this.item.id;
    });

    this.fetchServiceOptions();
    this.fetchCustomsDocs();

    this.addUserData();


  },
  watch: {
    'form.origin_country_id'(val) {
      this.showOriginCities = false;
      this.form.origin_city_id = '';

      if (val)
        this.showOriginCities = true;
    },
    'form.destination_country_id'(val) {
      this.showAirports = false;
      this.showCities = false;
      this.form.destination_airport_id = '';
      this.form.destination_city_id = '';

      if (val)
        this.showCities = true;
    },
    'form.is_call_requested'(val) {
      if (val)
        this.parcels = [];
    },
    'specialtyType'(val) {

      this.parcels = [];
      this.addParcel(this.form.service_category_id);

      if (val === 'specialty_vehicles' || val === 'specialty_fashion') {
        this.parcels.map(item => {
          item.parcel_type_id = val;
          return item;
        })
      }
    },
  },
  beforeDestroy() {
    this.$store.dispatch('heading/flushPortalHeadings');
  },
  computed: {
    parcelCount() {
      let sum = 0;
      for (const p of this.parcels) {
        sum += parseInt(p.quantity);
      }

      return sum;
    }
  },
  methods: {

    fetchPhoneCodes() {
      return this.axios.get(this.$urls.portal.phoneCodes.url, {params: {cursor: 1}})
          .then(response => {
            this.phoneCodes = response.data.data;
          }).catch(() => {
          });
    },

    addUserData() {

      if (!this.$store.getters['user/getPortalUserId'])
        return;

      if (this.$store.getters['user/isAgent']) {
        this.form.contact.name = this.$store.getters['user/getPortalUser'].agent.fullName;
      }

      if (this.$store.getters['user/getPortalUser'].level.id === 5) {
        this.form.contact.name = this.$store.getters['user/getPortalUser'].client.fullName;
      }

      this.form.contact.email = this.$store.getters['user/getPortalUser'].email;

    },

    fetchService() {
      return this.axios.get(this.$urls.portal.services.url, {params: {type: this.$route.params.type}})
          .then(response => {
            this.item = response.data.data;
          }).catch(() => {
          });
    },
    fetchServiceParameters() {
      return this.axios.get(this.$urls.portal.serviceParameters.url, {params: {type: this.$route.params.type}})
          .then(response => {
            this.options = response.data.data;
            this.serviceParameters = response.data.data;

            if (this.form.service_category_id === this.PETS_LIVE_ID) {
              this.serviceParameters.options = response.data.data.options.map(item => {
                item.title = this.getTranslation(item.title);
                return item;
              })
            }
          }).catch(() => {
          });
    },
    fetchServiceOptions() {
      this.axios.get(this.$urls.portal.serviceOptions.url)
          .then(response => {
            this.serviceOptions = response.data.data;
          }).catch(() => {
      });
    },
    fetchCustomsDocs() {
      this.axios.get(this.$urls.portal.customsDocuments.url)
          .then(response => {
            this.customsDocuments = response.data.data;
          }).catch(() => {
      });
    },
    saveApplication() {

      this.form.parcels = this.parcels;

      this.$v.$touch();

      if (this.$v.form.$invalid)
        return this.snotifyError('wrongFormMsg');

      if (this.$route.query.companyId)
        this.form.company_id = this.$route.query.companyId;

      this.axios.post(this.$urls.portal.sendApplication.url, this.form)
          .then(response => {

            Swal.fire({
              icon: 'success',
              title: this.getTranslation('yourAppIsSuccessfullySent'),
            });

            if (this.$store.getters['user/getPortalUserId'])
              this.$router.push({name: 'portal.client.account'}).catch(() => {
              });
            else
              this.$router.push({path: '/'}).catch(() => {
              });

          }).catch(() => {
      });
    },
  },
  validations() {
    if (this.form.service_category_id === this.BORDER_SERVICES || this.form.service_category_id === this.HOME_MOVE_ID || this.form.service_category_id === this.DANGEROUS_GOODS_ID) {
      return {
        form: {
          title: {
            required
          },
          service_category_id: {
            required
          },
          origin_country_id: {
            required
          },
          service_option_id: {
            required
          },
          destination_country_id: {
            required
          },
          address_origin: {
            required
          },
          address_destination: {
            required
          },
          shipment_date: {
            required
          },
          by_land: {
            required
          },
          needs_border_service: {
            required
          },
          contact: {
            mobile: {
              required
            },
            mobile_code_id: {
              required
            },
            email: {
              required
            },
            name: {
              required
            },
            type: {
              required
            },
          }
        }
      }
    } else {
      return {
        form: {
          title: {
            required
          },
          service_category_id: {
            required
          },
          origin_country_id: {
            required
          },
          service_option_id: {
            required
          },
          destination_country_id: {
            required
          },
          address_origin: {
            required
          },
          address_destination: {
            required
          },
          shipment_date: {
            required
          },
          by_land: {
            required
          },
          needs_border_service: {
            required
          },
          parcels: {
            required
          },
          contact: {
            mobile: {
              required
            },
            email: {
              required
            },
            name: {
              required
            },
            type: {
              required
            },
          }
        }
      }
    }
  }

}
</script>

<style scoped>

</style>

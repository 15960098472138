const generalMixin = {
    data() {
        return {
            unitOptions: {
                dimension: [],
                age: [],
                weight: []
            },
            GENERAL_CARGO_ID: 1,
            DANGEROUS_GOODS_ID: 2,
            PARCELS_ID: 3,
            PETS_LIVE_ID: 4,
            PERISHABLES_ID: 5,
            PHARMA_ID: 6,
            MEDICAL_ID: 7,
            SPECIALTY_ID: 8,
            HOME_MOVE_ID: 9,
            BORDER_SERVICES: 10,

            CONTACT_SENDER: 1,
            CONTACT_RECEIVER: 2,
        }
    },
    methods: {
        fetchUnitOptions() {
            this.axios.get(this.$urls.portal.unitOptions.url)
                .then(response => {
                    this.unitOptions = response.data.data;
                }).catch(() => {
            });
        }
    }
};

export default generalMixin;